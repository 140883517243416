<template>
  <div class="mobile-number">
    <div class="wrapper">
      <div class="title font-md">
        <span>휴대전화 번호 입력</span>
      </div>
      <div class="desc font-sm"> 휴대전화 번호를 입력하신 후 저장하기 버튼을 눌러주세요.</div>
      <div class="content">
        <div class="form-group">
          <label :for="`${component.name}Mobile`" class="font-sm">휴대전화</label>
          <Phone :id="`${component.name}Mobile`" placeholder="ex) 01012345678" :value.sync="state.form.mobile" :enter="submit"/>
        </div>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-point btn-block font-sm" @click="submit()">저장하기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";

function Component(initialize) {
  this.name = "modalMobileNumber";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Phone},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      form: {
        mobile: ""
      }
    });

    const submit = () => {
      const args = lib.getRenewed(state.form);

      if (!args.mobile.trim()) {
        document.getElementById(`${component.name}Mobile`).focus();
        return store.commit("setSwingMessage", "휴대전화 번호를 입력해주세요.");
      } else if (!lib.isValidMobileNum(args.mobile)) {
        document.getElementById(`${component.name}Mobile`).focus();
        return store.commit("setSwingMessage", "휴대전화 번호 값이 유효하지 않습니다.");
      }

      http.put(`/api/members/${store.state.account.memberSeq}/account/mobile`, args).then(() => {
        store.commit("checkAccount");
        store.commit("setSwingMessage", "휴대전화 번호를 저장하였습니다.");
        store.commit("closeModal", {name: component.name});
      });
    };

    return {component, state, submit};
  },
});
</script>

<style lang="scss" scoped>
.mobile-number {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .content {
      .form-group {
        margin-bottom: 0;

        input[type=text] {
          height: $formHeight;
        }
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>